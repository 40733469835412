import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
}

const images = [<StaticImage className="h-full w-full object-cover" src="../images/contemporary.jpg" alt="Aluminium Windows and Doors for a Beautiful Home" />, <StaticImage className="h-full w-full object-cover" src="../images/panoramic.jpg" alt="Aluminium Windows and Doors for a Beautiful Room" />]

export default function Hero() {
  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      {/* <h1>Aluminium Windows and Doors</h1>
      <h2 className="lead">Made to Measure in Seychelles since 2001.</h2>
      <div className="p-3 text-justify">
        <h3>Build your perfect home</h3>
        <p>Whether you are building your perfect home or enhancing an existing room, our made to measure aluminium products can be designed to meet your specific needs. Our range of aluminium windows and doors have the highest functionality with many different beautiful styles.</p>
      </div> */}
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="relative sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0 bg-white">{image}</div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">Aluminium Windows and Doors</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-blue-200 sm:max-w-3xl bg-blue-700 p-3 bg-opacity-80">Made to Measure in Seychelles since 2001.</p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <Link to="/" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50 sm:px-8">
                  Quotation
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
