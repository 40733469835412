import React from "react"

import Layout from "../layouts/main"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Testimonial from "../components/testimonial"

export default function IndexPage() {
  return (
    <Layout>
      <Seo title="Seychelles Aluminium Windows and Doors Specialist" description="Seychelles aluminium windows and doors specialist since 2001. We have a wide selection of products: Casement, Sliding and folding frames with Clear, Bronze, Tinted, Mirror and Laminated Safely Glass" />
      <Hero />
      <Testimonial />
    </Layout>
  )
}
